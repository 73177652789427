<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="stat-title">
          {{ $t('home.stat.successRate') }}
        </b-card-title>
        <b-card-sub-title>{{ $t('home.stat.forPeriod') }}</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          :disabled="!isLoaded"
          @on-change="reloadStat"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>
    <b-overlay
      :show="!isLoaded"
      rounded="sm"
    >
      <b-card-body>
        <vue-apex-charts
          ref="chart"
          type="area"
          height="400"
          :options="apexChartConfig.lineAreaChartSpline.chartOptions"
          :series="apexChartConfig.lineAreaChartSpline.series"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import apexChartConfig from './apexChartConfig'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    BOverlay,
  },
  data() {
    return {
      apexChartConfig,
      // rangePicker: ['2019-05-01', '2019-05-10'],
      rangePicker: [],
      isLoaded: false,
      d1: null,
      d2: null,
    }
  },
  mounted() {
    this.d2 = new Date()
    this.d1 = new Date(this.d2)
    this.d1.setDate(this.d1.getDate() - 6)
    this.rangePicker = [`${this.d1.getFullYear()}-${this.d1.getMonth() + 1}-${this.d1.getDate()}`, `${this.d2.getFullYear()}-${this.d2.getMonth() + 1}-${this.d2.getDate()}`]
    this.loadData(true)
  },
  methods: {
    loadData(neeedToCalcShortStat) {
      useJwt.getUserstat(this.d1.getFullYear(), this.d1.getMonth() + 1, this.d1.getDate(), this.d2.getFullYear(), this.d2.getMonth() + 1, this.d2.getDate(), {})
        .then(response => {
          const resData = response.data
          resData.series.forEach((ser, i) => {
            resData.series[i].name = this.$t(ser.name)
          })
          this.apexChartConfig.lineAreaChartSpline.series = resData.series
          this.apexChartConfig.lineAreaChartSpline.chartOptions.yaxis.forEach((y, i) => {
            this.apexChartConfig.lineAreaChartSpline.chartOptions.yaxis[i].title.text = this.$t(y.title.text)
          })
          this.apexChartConfig.lineAreaChartSpline.chartOptions.xaxis.categories = resData.dates
          if (neeedToCalcShortStat) {
            this.$emit('stat-completed', this.calculatedShortStat())
          }
          this.$refs.chart.refresh()
          this.isLoaded = true
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.errGetStat'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email ? error.data.email : '',
            },
          })
        })
        .finally(() => {
        })
    },
    reloadStat(selectedDate) {
      const sd = selectedDate
      if (sd.length === 2) {
        this.isLoaded = false
        const currentdate = new Date()
        if (sd[1].getTime() > currentdate.getTime()) {
          sd[1] = currentdate
        }
        [this.d1, this.d2] = sd
        this.loadData(false)
      }
    },
    calculatedShortStat() {
      let workingDays = 0
      let daysInARow = 0
      let was0 = false
      const d = this.apexChartConfig.lineAreaChartSpline.series[0].data
      // const d = [0, 0, 0, 0, 0, 0, 0]
      // const d = [0, 0, 0, 0, 0, 0, 100]
      // const d = [0, 0, 0, 0, 100, 100, 100]
      // const d = [0, 0, 0, 100, 0, 100, 100]
      // const d = [100, 100, 100, 100, 100, 100, 100]
      const dlen = d.length
      for (let i = dlen - 1; dlen - i <= 7; i -= 1) {
        if (d[i] > 0) {
          workingDays += 1
          if (!was0) {
            daysInARow += 1
          }
        } else was0 = true
      }
      return {
        workingDays,
        daysInARow,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card .card-header {
  border-bottom: none;
  padding: 1.5rem 1.5rem 0 1.5rem;
  }
  input.flat-picker {
    padding: 0.438rem 0.5rem;
    min-width: 185px;
  }

  .card-title.stat-title {
    margin-bottom: 10px;
  }
</style>
