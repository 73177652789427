/*
import { $themeColors } from '@themeConfig'

// heat chart data generator
function generateDataHeatMap(count, yrange) {
  let i = 0
  const series = []
  while (i < count) {
    const x = `w${(i + 1).toString()}`
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    series.push({
      x,
      y,
    })
    // eslint-disable-next-line no-plusplus
    i++
  }
  return series
}
*/

// colors
const chartColors = {
  // column: {
  //   series1: '#826af9',
  //   series2: '#d2b0ff',
  //   bg: '#f8d3ff',
  // },
  // success: {
  //   shade_100: '#7eefc7',
  //   shade_200: '#06774f',
  // },
  // donut: {
  //   series1: '#ffe700',
  //   series2: '#00d4bd',
  //   series3: '#826bf8',
  //   series4: '#2b9bf4',
  //   series5: '#FFA1A1',
  // },
  area: {
    series3: '#b1acf8',
    series2: '#60f2ca',
    // series1: '#f8d2ac',
  },
}

export default {

  lineAreaChartSpline: {
    series: [
      // {
      //   name: 'Messages',
      //   data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
      //   // type: 'line',
      // },
      // {
      //   name: 'New phrases',
      //   data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
      //   // type: 'line',
      // },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true,
            customIcons: [],
          },
          // autoSelected: null,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 350,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 500,
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
      },
      stroke: {
        show: true,
        curve: 'straight',
        width: [4, 4],
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },

      },
      xaxis: {
        type: 'date',
        categories: [
          // '7/12',
          // '8/12',
          // '9/12',
          // '10/12',
          // '11/12',
          // '12/12',
          // '13/12',
          // '14/12',
          // '15/12',
          // '16/12',
          // '17/12',
          // '18/12',
          // '19/12',
          // '20/12',
        ],
      },
      yaxis: [
        {
          title: {
            text: 'Messages',
            // offsetX: -20,
            offsetY: 0,
          },
        },
        {
          opposite: true,
          title: {
            text: 'Phrases',
          },
        },
      ],
      fill: {
        opacity: 0.5,
        type: 'solid',
      },
      tooltip: {
        shared: true,
        enabled: true,
        followCursor: true,
        // fillSeriesColor: true,
      },
      colors: [chartColors.area.series3, chartColors.area.series2],
    },
  },

}
