<template>
  <div>

    <b-row class="match-height">
      <b-card-group class="col-xl-4 col-md-6">
        <home-congratulation
          :data="shortStat"
          :name="userData.name"
        />
      </b-card-group>
      <b-card-group class="col-xl-4 col-md-6">
        <home-medal
          :data="shortStat"
        />
      </b-card-group>
      <b-card-group class="col-xl-4 col-md-6">
        <b-card no-body class="card-user" >
          <b-card-header>
            <b-card-title>{{ $t('home.account') }}</b-card-title>
            <b-card-text class="mr-25 mb-0">
              {{ userData.name }}
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row class="match-height">

              <b-col md="5" sm="12" class="mb-2 mb-md-0 coluserstat">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar v-if="userData.is_premium" size="48" variant="success"><feather-icon size="24" icon="UserIcon" /></b-avatar>
                    <b-avatar v-else size="48" variant="danger"><feather-icon size="24" icon="UserIcon" /></b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ $t('home.accStatus') }}</h4>
                    <b-card-text class="font-small-3 mb-0">{{ getStatus() }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col md="7" sm="12" class="mb-2 mb-md-0 coluserstat">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar v-if="userData.is_premium" size="48" variant="info"><feather-icon size="24" icon="CalendarIcon" /></b-avatar>
                    <b-avatar v-else size="48" variant="success"><feather-icon size="24" icon="AwardIcon" /></b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ $t('home.subscription') }}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      <span v-if="!userData.is_premium"><b><a href="/profile">{{ $t('home.getPremium') }}</a></b></span>
                      <span v-else>{{ $t('home.due') }} {{ userData.subscription_period_end | formatDate }}</span>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

            </b-row>
          </b-card-body>
        </b-card>
      </b-card-group>
    </b-row>

    <b-row class="match-height mt-1">
      <b-card-group class="col-md-12">
        <home-statistics
          @stat-completed="statCompleted"
        />
      </b-card-group>
    </b-row>

    <b-row class="blog-list-wrapper match-height_ mt-1">
      <b-col
        v-for="blog in blogList"
        :key="blog.id"
        md="3"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'blog-detail', params: { id: blog.id } }">
            <b-img
              :src="blog.thumbnails"
              alt="Image"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'blog-detail', params: { id: blog.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ blog.title }}
              </b-link>
            </b-card-title>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center text-body">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ blog.date | formatDate }}</span>
              </div>
              <b-link
                :to="{ name: 'blog-detail', params: { id: blog.id } }"
                class="font-weight-bold"
              >
                {{ $t('home.readMore') }}
              </b-link>
            </div>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BCardGroup, BImg,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import HomeCongratulation from './HomeCongratulation.vue'
import HomeStatistics from './HomeStatistics.vue'
import HomeMedal from './HomeMedal.vue'
import { clearUserDataPartial } from '@/auth/utils'

export default {
  components: {
    // eslint-disable-next-line object-property-newline
    HomeCongratulation, HomeStatistics, HomeMedal, BCard, BCardText, BLink, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
    // eslint-disable-next-line object-property-newline
    BCardGroup, BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      userData: JSON.parse(localStorage.getItem('userData')),

      freeAccountLimit: 200,
      blogList: [],
      // shortStat: { workingDays: 7 },
      shortStat: null,
      // statisticsItems: [
      //   {
      //     icon: 'TrendingUpIcon',
      //     color: 'light-primary',
      //     title: '230k',
      //     subtitle: 'Sales',
      //     customClass: 'mb-2 mb-xl-0',
      //   },
      //   {
      //     icon: 'UserIcon',
      //     color: 'light-info',
      //     title: 'Account status',
      //     subtitle: this.getStatus(),
      //     customClass: 'mb-2 mb-xl-0',
      //   },
      //   {
      //     icon: 'BoxIcon',
      //     color: 'light-danger',
      //     title: '1.423k',
      //     subtitle: 'Products',
      //     customClass: 'mb-2 mb-sm-0',
      //   },
      //   {
      //     icon: 'DollarSignIcon',
      //     color: 'light-success',
      //     title: '$9745',
      //     subtitle: 'Revenue',
      //     customClass: '',
      //   },
      // ],
    }
  },
  mounted() {
    this.getUserProfile()
    this.loadNews()
  },
  methods: {
    getStatus() {
      if (this.userData.is_premium) {
        return this.$t('premium')
      }
      return this.$t('limited')
    },

    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // localStorage.removeItem('userData')
      clearUserDataPartial()
      this.$router.push({ name: 'auth-login', params: { needToRefreshPage: true } })
    },

    getUserProfile() {
      useJwt.getUserProfile({
        // src_text: this.textQuiz,
      })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          // this.items = resData
          // Set the initial number of items

          this.userData.id = resData[0].id
          this.userData.name = resData[0].name
          this.userData.credits = resData[0].credits
          this.userData.email = resData[0].email
          this.userData.is_premium = resData[0].is_premium
          this.userData.subscription_period_end = resData[0].subscription_period_end
          this.userData.stripe_customer_id = resData[0].stripe_customer_id
          this.userData.msg_count = resData[0].msg_count
          this.userData.msg_limit = resData[0].msg_limit

          // this.userData.symbols_free_alg = resData[0].symbols_free_alg
          // this.userData.symbols_paid_alg = resData[0].symbols_paid_alg
          // this.userData.char_limit_alg_free = resData[0].char_limit_alg_free
          // this.userData.char_limit_alg_paid = resData[0].char_limit_alg_paid
          this.userData.subscription_name = resData[0].subscription_name
          this.userData.subscription_code = resData[0].subscription_code
          this.userData.avatar = resData[0].avatar
          this.userData.native_language = resData[0].native_language
          this.userData.native_language_name = resData[0].native_language_name
          this.userData.interface_language = resData[0].interface_language

          localStorage.setItem('userData', JSON.stringify(this.userData))
          // document.getElementById('nb-username').innerHTML =this.userData.name;
          this.updateNavbarProfile()
          // NavBar.userData = this.userData;
          // window.location.reload(true);
          // NavBar.forceReload += 1;
          // store.state.appConfig.globalUserData = userData;
          // console.log(this.$store.appConfig.globalUserData);
          // console.log(store.state.appConfig.isRTL)
        })
        .catch(error => {
          // this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('toast.errGetUserProfile'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email ? error.data.email : '',
              },
            })
          }
        })
        .finally(() => {
          // this.seenTable = true
        })
    },

    loadNews() {
      useJwt.getNewsHome({
      })
        .then(response => {
          const resData = response.data
          this.blogList = resData
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.errGetNews'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email ? error.data.email : '',
            },
          })
        })
    },

    updateNavbarProfile() {
      document.getElementById('nb-username').innerHTML = this.userData.name
      const ps = document.getElementById('profile-state').getElementsByClassName('b-avatar-badge')[0]
      if (this.userData.is_premium) {
        ps.classList.remove('badge-secondary')
        ps.classList.add('badge-success')
      } else {
        ps.classList.remove('badge-success')
        ps.classList.add('badge-secondary')
      }
    },
    routeToCreateText() {
      this.$router.push({ name: 'addtext' })
    },
    routeToQuizzes() {
      this.$router.push({ name: 'quizzes-page' })
    },

    limit_to_str(lim) {
      return lim > 0 ? lim : '∞'
    },

    limit_to_int(lim) {
      return lim > 0 ? lim : 2147483647
    },

    getVariant(symbols, limit) {
      if (limit === 0) {
        return 'success'
      }
      if ((symbols / limit) > 0.7) {
        return 'danger'
      } else
      // eslint-disable-next-line no-else-return
      if ((symbols / limit) > 0.4) {
        return 'warning'
      } else return 'success'
    },

    statCompleted(shortStat) {
      this.shortStat = shortStat
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss" scoped>
  .card-user .statistics-body {
    padding: 2rem 1.5rem 2.8rem 1.5rem;
  }

</style>
