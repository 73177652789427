<template>
  <b-card
    class="card-congratulation-medal"
  >
    <h5 v-if="data">
      <span v-if="data.daysInARow >= 3">{{ $t('home.medal.congratulation') }}</span>
      <span v-else>{{ $t('home.medal.work3days') }}</span>
    </h5>
    <b-card-text
      v-if="data"
      class="font-small-3">
      <span v-if="data.daysInARow == 7">{{ $t('home.medal.wonGoldMedal') }}</span>
      <span v-else-if="data.daysInARow >= 3">{{ $t('home.medal.wonSilverMedal') }}</span>
      <span v-else>{{ $t('home.medal.toGetAMedal') }}</span>
    </b-card-text>
    <h3
      v-if="data"
      class="mb-75 mt-2 pt-50"
    >
      <span v-if="data.daysInARow <=1 ">{{ $t('home.medal.havntStudiedAFewDays') }}</span>
      <span v-else>{{ $t('home.medal.workOutDaysInARow', { days: data.daysInARow }) }}</span>
    </h3>
    <b-button
      v-if="data"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="routeToChat"
    >
      <span v-if="data.daysInARow <= 1">{{ $t('home.medal.doItNow') }}</span>
      <span v-else>{{ $t('home.medal.keepItUp') }}</span>
    </b-button>
    <b-img
      v-if="data && data.daysInARow >= 3"
      :src="(data.daysInARow >= 3 && data.daysInARow != 7) ? require('@/assets/images/illustration/badge_silver.svg'): require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
    <div class="h-100 align-items-center d-flex justify-content-center">
      <b-spinner
        v-if="!data"
        class="mr-1"
        variant="secondary"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText,
  //   BLink,
  BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    // BLink,
    BImg,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // kFormatter,
    routeToChat() {
      this.$router.push({ name: 'apps-chat' })
    },

  },
}
</script>

<style lang="scss" scoped>
    .congratulation-medal {
        opacity: 0.7;
    }

</style>
