<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <b-img
      v-if="data"
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
      :class="{ gray: data.workingDays <=0 }"
    />
    <b-img
      v-if="data"
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
      :class="{ gray: data.workingDays <=0 }"
    />

    <b-avatar
      v-if="data"
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        :icon="(data.workingDays > 0) ? 'AwardIcon': 'MehIcon'"
      />
    </b-avatar>
    <h1
      v-if="data"
      class="mb-1 mt-50 text-white"
    >
      <span v-if="data.workingDays > 0">{{ $t('home.congrat.congratulations') }}, </span>{{ name }},
    </h1>
    <b-card-text
      v-if="data"
      class="m-auto w-75"
    >
      <span v-if="data.workingDays == 7">{{ $t('home.congrat.completed7Days') }}</span>
      <span v-else-if="data.workingDays == 1">{{ $t('home.congrat.worked1DayIn7') }}</span>
      <span v-else-if="data.workingDays != 0">{{ $t('home.congrat.workedDaysIn7', { days: data.workingDays }) }}</span>
      <span v-else>{{ $t('home.congrat.neverWorkedIn7') }}</span>
    </b-card-text>
    <div class="h-100 align-items-center d-flex justify-content-center">
      <b-spinner
        v-if="!data"
        class="mr-1"
        variant="info"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText, BSpinner,
} from 'bootstrap-vue'
// import { MehIcon } from 'vue-feather-icons'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BSpinner,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
  .congratulations-img-left.gray, .congratulations-img-right.gray {
    filter: grayscale(0.5);
    opacity: 0.3;
  }
</style>
